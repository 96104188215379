// ** Redux Imports

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { newsections } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'

// CATEGORIES
// CREATE CLASS CATEGORY 
export const createClassCategory = createAsyncThunk('clinicNewSections/createClassCategory', async (data) => {
  const response = await axiosRequest.post(newsections.CREATE_CLASS_CATEGORY, data)
  console.log('-------createClassCategory---response----', response?.data)
  return { status: response?.data?.status }
})

// CREATE RESOURCES
export const changeClassCategory = createAsyncThunk('clinicNewSections/changeClassCategory', async (data) => {
  const response = await axiosRequest.post(newsections?.CHANGE_CLASS_CATEGORY, data)
  return { status: response?.data?.status ?? [] }
})

// GET CLASS CATEGORIES
export const getClassCategories = createAsyncThunk('clinicNewSections/getClassCategories', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_CLASS_CATEGORY, data)
  return { data: response?.data?.data ?? [] }
})

// GET Class CATEGORIES BY TYPE
export const getClassCategoriesByType = createAsyncThunk('clinicNewSections/getClassCategoriesByType', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_CATEGORY_BY_TYPE, data)
  return { data: response?.data?.data ?? [] }
})

// GET MEDITATION CATEGORIES BY TYPE
export const getMeditationCategoriesByType = createAsyncThunk('clinicNewSections/getMeditationCategoriesByType', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_CATEGORY_BY_TYPE, data)
  return { data: response?.data?.data ?? [] }
})

// CLASSES
// CREATE CLASSES
export const createClasses = createAsyncThunk('clinicNewSections/createClasses', async (data) => {
  const response = await axiosRequest.post(newsections?.CREATE_CLASSES, data)
  return { status: response?.data?.status }
})
// GET CLASS BY TYPE
export const getClassByType = createAsyncThunk('clinicNewSections/getClassByType', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_CLASS_BY_TYPE, data)
  return { data: response?.data?.data ?? [] }
})

// GET ALL SHARED CLASSESS
export const getAllSharedClasses = createAsyncThunk('clinicNewSections/getAllSharedClasses', async () => {
  const response = await axiosRequest.get(newsections?.GET_ALL_SHARED_CLASSES)
  return { data: response?.data?.data ?? [] }
})

export const setAllSharedClasses = createAsyncThunk('clinicNewSections/setAllSharedClasses', async (data) => {
  return { data }
})

// GET ALL SHARED MEDITATION
export const getAllSharedMeditation = createAsyncThunk('clinicNewSections/getAllSharedMeditation', async () => {
  const response = await axiosRequest.get(newsections?.GET_ALL_SHARED_MEDITATION)
  return { data: response?.data?.data ?? [] }
})

export const setAllSharedMeditation = createAsyncThunk('clinicNewSections/setAllSharedMeditation', async (data) => {
  return { data }
})
export const updateAllSharedClasses = createAsyncThunk('clinicNewSections/updateAllSharedClasses', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_ALL_SHARED_CLASSES, data)
  return { status: response?.data?.status ?? [] }
})

// GET ALL CLASSESS
export const getAllClasses = createAsyncThunk('clinicNewSections/getAllClasses', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_ALL_CLASSES, data)
  return { data: response?.data?.data ?? [] }
})

// GET ALL MEDITATION
export const getAllMeditation = createAsyncThunk('clinicNewSections/getAllMeditation', async () => {
  const response = await axiosRequest.post(newsections?.GET_MEDITATION_BY_CATEGORY)
  return { data: response?.data?.data ?? [] }
})

// DELETE CLASSESS
export const deleteClasses = createAsyncThunk('clinicNewSections/deleteClasses', async (data) => {
  const response = await axiosRequest.post(newsections?.DELETE_CLASSES, data)
  console.log('-----deleteClasses---response----', response?.data?.status)
  return { status: response?.data?.status ?? false }
})

// ADD TO FEATURE
export const addToFeature = createAsyncThunk('clinicNewSections/addToFeature', async (data) => {
  const response = await axiosRequest.post(newsections?.ADD_TO_FEATURE, data)
  console.log('-----addToFeature---response----', response?.data?.status)
  return { status: response?.data?.status ?? false }
})

export const deleteCatagories = createAsyncThunk('clinicNewSections/deleteCatagories', async (data) => {
  const response = await axiosRequest.post(newsections?.DELETE_CATAGORIES, data)
  console.log('-----deleteClasses---response----', response?.data?.status)

  return { status: response?.data?.status ?? false }
})

export const deleteSubCatagories = createAsyncThunk('clinicNewSections/deleteSubCatagories', async (data) => {
  const response = await axiosRequest.post(newsections?.DELETE_CATAGORIES, data)
  console.log('-----deleteClasses---response----', response?.data?.status)

  return { status: response?.data?.status ?? false }
})

export const deleteMeditaion = createAsyncThunk('clinicNewSections/deleteMeditaion', async (data) => {
  console.log('-----deleteClasses---data----', data)
  const response = await axiosRequest.post(newsections?.DELETE_MEDITATION, data)
  console.log('-----deleteClasses---response----', response?.data?.status)
  return { status: response?.data?.status ?? false }
})
//GET COMPLETE CLASS
export const getCompleteClass = createAsyncThunk('clinicNewSections/getCompleteClass', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_COMPLETE_CLASSES, data)
  console.log('------getCompleteClass--response---', response?.data?.data)
  return { data: response?.data?.data ?? {} }
})

export const setCompleteClassData = createAsyncThunk('clinicNewSections/setCompleteClassData', async (data) => {
  console.log('------setCompleteClassData-----data---', data)
  return { data }
})
// CREATE CLASSES
export const updateClass = createAsyncThunk('clinicNewSections/updateClass', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_CLASSES, data)
  console.log('----updateClass----response----', response)
  return { status: response?.data?.status }
})

// CREATE CLASSES
export const updateClassVideo = createAsyncThunk('clinicNewSections/updateClassVideo', async (data, { dispatch }) => {
  const response = await axiosRequest.post(newsections?.UPDATE_CLASSES, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (data) => {
      dispatch(setClassVideoUplader(Math.round(100 * (data.loaded / data.total))))
    }
  })
  return { status: response?.data?.status }
})

export const setClassVideoUplader = createAsyncThunk('clinicNewSections/setClassVideoUplader', async (data) => {
  console.log('-----onUploadProgress--data---', data)
  return { data }
})
// ADD MEDITATION AUDIO
export const updateMeditationAudio = createAsyncThunk('clinicNewSections/updateMeditationAudio', async (data, { dispatch }) => {
  const response = await axiosRequest.post(newsections?.UPDATE_MEDITATION, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (data) => {
      dispatch(setMeditationAudioUplader(Math.round(100 * (data.loaded / data.total))))
    }
  })
  return { status: response?.data?.status }
})

export const setMeditationAudioUplader = createAsyncThunk('clinicNewSections/setMeditationAudioUplader', async (data) => {
  return { data }
})

// GET MORE MEDITATION AUDIOS
export const getMeditationMoreAudios = createAsyncThunk('clinicNewSections/getMeditationMoreAudios', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_MORE_MEDITATION_AUDIOS, data)
  console.log('------getMeditationMoreAudios-----response----', response?.data?.data)
  return { data: response?.data?.data }
})

export const setMeditationMoreAudios = createAsyncThunk('clinicNewSections/setMeditationMoreAudios', async (data) => {
  return { data }
})

// ADD MORE MEDITATION AUDIOS
export const createMeditationMoreAudios = createAsyncThunk('clinicNewSections/createMeditationMoreAudios', async (data, { dispatch }) => {
  const response = await axiosRequest.post(newsections?.CREATE_MORE_MEDITATION_AUDIOS, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (data) => {
      dispatch(setMeditationAudioUplader(Math.round(100 * (data.loaded / data.total))))
    }
  })
  return { status: response?.data?.status }
})

//
export const addDuration = createAsyncThunk('clinicNewSections/addDuration', async (data) => {
  console.log("--------------------data", data)
  const response = await axiosRequest.post(newsections?.UPDATE_MEDITATION, data)
  return { status: response?.data?.status }
})

// UPDATE MORE MEDITATION AUDIOS
export const updateMeditationMoreAudios = createAsyncThunk('clinicNewSections/updateMeditationMoreAudios', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_MORE_MEDITATION_AUDIOS, data)
  console.log('------updateMeditationMoreAudios-----response----', response?.data)
  return { status: response?.data?.status }
})
export const updateMeditationMoreAudio = createAsyncThunk('clinicNewSections/updateMeditationMoreAudio', async (data, { dispatch }) => {
  const response = await axiosRequest.post(newsections?.UPDATE_MORE_MEDITATION_AUDIOS, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (data) => {
      dispatch(setMeditationAudioUplader(Math.round(100 * (data.loaded / data.total))))
    }
  })
  return { status: response?.data?.status }
})
// DELETE MORE MEDITATION AUDIOS
export const deleteMeditationMoreAudios = createAsyncThunk('clinicNewSections/deleteMeditationMoreAudios', async (data) => {
  console.log('------deleteMeditationMoreAudios-----data----', data)

  const response = await axiosRequest.post(newsections?.DELETE_MORE_MEDITATION_AUDIOS, data)
  console.log('------deleteMeditationMoreAudios-----response----', response?.data)
  return { status: response?.data?.status }
})
// ADD CLASSES FAVOURIT
export const addFavouritClass = createAsyncThunk('clinicNewSections/addFavouritClass', async (data) => {
  const response = await axiosRequest.post(newsections?.ADD_FAVOURIT_CLASSES, data)
  console.log('----addFavouritClass----response----', response)
  return { status: response?.data?.status }
})

// REMOVE CLASSES FAVOURIT
export const removeFavouritClass = createAsyncThunk('clinicNewSections/removeFavouritClass', async (data) => {
  const response = await axiosRequest.post(newsections?.REMOVE_FAVOURIT_CLASSES, data)
  console.log('----removeFavouritClass----response----', response)
  return { status: response?.data?.status }
})

// UPDATE LESSON
export const updateLesson = createAsyncThunk('clinicNewSections/updateLesson', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_LESSONS, data)
  console.log('----updateClass----response----', response)
  return { status: response?.data?.status }
})

// RECOMMENDED
// CREATE RECOMMENDED
export const createRecommendedData = createAsyncThunk('clinicNewSections/createRecommendedData', async (recommended) => {
  const response = await axiosRequest.post(newsections?.CREATE_RECOMMENDED_DATA, { recommended })
  return { status: response?.data?.status }
})

// CREATE RECOMMENDED MEDITATION
export const createRecommendedMeditation = createAsyncThunk('clinicNewSections/createRecommendedMeditation', async (recommended) => {
  const response = await axiosRequest.post(newsections?.CREATE_RECOMMENDED_MEDITATION, { recommended })
  return { status: response?.data?.status }
})

// POPULAR
// CREATE POPULAR
export const createPopularData = createAsyncThunk('clinicNewSections/createPopularData', async (popular) => {
  const response = await axiosRequest.post(newsections?.CREATE_POPULAR_DATA, { popular })
  return { status: response?.data?.status }
})

// CREATE POPULAR MEDITATION
export const createPopularMeditation = createAsyncThunk('clinicNewSections/createPopularMeditation', async (popular) => {
  const response = await axiosRequest.post(newsections?.CREATE_POPULAR_MEDITATION, { popular })
  return { status: response?.data?.status }
})
// MEDITATION
// CREATE MEDITATION
export const createMeditations = createAsyncThunk('clinicNewSections/createMeditations', async (data) => {
  const response = await axiosRequest.post(newsections?.CREATE_MEDITATION, data)
  console.log('------------createMeditations-------response----', response?.data?.status)
  return { status: response?.data?.status, data: response?.data  ?? [] }
})

// UPDATE MEDITATION
export const updateMeditation = createAsyncThunk('clinicNewSections/updateMeditation', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_MEDITATION, data)
  console.log('----updateMeditation----response----', response)
  return { status: response?.data?.status }
})

export const updateClassCategory = createAsyncThunk('clinicNewSections/updateClassCategory', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_CLASS_CATEGORY, data)
  console.log('----updateMeditation----response----', response)
  return { status: response?.data?.status }
})

export const updateDurationMeditation = createAsyncThunk('clinicNewSections/updateDurationMeditation', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_MEDITATION, data)
  return { status: response?.data?.status }
})

export const getMeditationCategries = createAsyncThunk('clinicNewSections/getMeditationCategries', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_CLASS_CATEGORY, data)
  return { data: response?.data?.data ?? [] }
})

export const getLessonMeditation = createAsyncThunk('clinicNewSections/getLessonMeditation', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_LESSON_MEDITATION, data)
  return { data: response?.data?.data ?? [] }
})

//GET COMPLETE MEDITATION
export const getCompleteMeditation = createAsyncThunk('clinicNewSections/getCompleteMeditation', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_COMPLETE_MEDITAIION, data)
  return { data: response?.data?.data ?? {} }
})
// GET MEDITATION BY TYPE
export const getMeditationByType = createAsyncThunk('clinicNewSections/getMeditationByType', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_MEDITATION_BY_TYPE, data)
  return { data: response?.data?.data ?? [] }
})

//
export const getFeedBackData = createAsyncThunk('clinicNewSections/getFeedBackData', async (id) => {
  const response = await axiosRequest.post(newsections?.GET_Feed_BACK_DATA, id)
  return { data: response.data?.data ?? [] }
})

// GET MEDITATION BY CATEGORIE
export const getMeditationByCategory = createAsyncThunk('clinicNewSections/getMeditationByCategory', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_MEDITATION_BY_CATEGORY, data)
  return { data: response?.data?.data ?? [] }
})

// CREATE RESOURCES
export const changeMeditationCategory = createAsyncThunk('clinicNewSections/changeMeditationCategory', async (data) => {
  const response = await axiosRequest.post(newsections?.CHANGE_MEDITATION_CATEGORY, data)
  return { status: response?.data?.status ?? [] }
})

// ADD MEDITATION FAVOURIT
export const addFavouritMeditation = createAsyncThunk('clinicNewSections/addFavouritMeditation', async (data) => {
  const response = await axiosRequest.post(newsections?.ADD_FAVOURIT_MEDITATION, data)
  console.log('----addFavouritClass----response----', response)
  return { status: response?.data?.status }
})

// REMOVE MEDITATION FAVOURIT
export const removeFavouritMeditation = createAsyncThunk('clinicNewSections/removeFavouritMeditation', async (data) => {
  const response = await axiosRequest.post(newsections?.REMOVE_FAVOURIT_MEDITATION, data)
  console.log('----removeFavouritClass----response----', response)
  return { status: response?.data?.status }
})

// SEND NOTIFICATION
export const sendnotifications = createAsyncThunk('clinicNewSections/sendnotifications', async (data) => {
  const response = await axiosRequest.post(newsections?.SEND_NOTIFICATIONS, data)
  console.log('----removeFavouritClass----response----', response)
  return { status: response?.data?.status }
})

// GET CLASSES BY CATEGORIE
export const getClassesByCategory = createAsyncThunk('clinicNewSections/getClassesByCategory', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_CLASS_BY_CATEGORY, data)
  return { data: response?.data?.data ?? [] }
})
// GET RECOMMENDED BY TYPE
export const getRecommendByType = createAsyncThunk('clinicNewSections/getRecommendByType', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_CLASS_BY_TYPE, data)
  return { data: response?.data?.data ?? [] }
})
// GET POPULAR BY TYPE
export const getPopularByType = createAsyncThunk('clinicNewSections/getPopularByType', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_CLASS_BY_TYPE, data)
  return { data: response?.data?.data ?? [] }
})

// GET AUTHORS LIST
export const getAuthorsList = createAsyncThunk('clinicNewSections/getAuthorsList', async () => {
  const response = await axiosRequest.get(newsections?.GET_AUTHORS_LIST)
  console.log('-------getAuthorsList---response------', response?.data)
  return { data: response?.data?.data ?? [] }
})

// LESSONS
// ADD LESSONS
export const createLessons = createAsyncThunk('clinicNewSections/createLessons', async (data) => {
  const response = await axiosRequest.post(newsections?.CREATE_LESSONS, data)
  console.log('----createLessons---response---', response?.data)
  return { status: response?.data?.status, data: response?.data?.lesson_id }
})
// GET LESSON AND LESSONS CONTENTS
export const getLessonsAndContents = createAsyncThunk('clinicNewSections/getLessonsAndContents', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_LESSONS_AND_CONTENTS, data)
  return { data: response?.data?.data ?? {} }
})

// LESSONS CONTENTS
// ADD LESSONS CONTENTS
export const createLessonContents = createAsyncThunk('clinicNewSections/createLessonContents', async (data) => {
  const response = await axiosRequest.post(newsections?.CREATE_LESSONS_CONTENTS, data)
  console.log('------createLessonContents-----response----', response?.data)
  return { status: response?.data?.status }
})

// EDIT LESSON CONTENTS
export const updateLessonContents = createAsyncThunk('clinicNewSections/updateLessonContents', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_LESSONS_CONTENTS, data)
  console.log('---updateLessonContents----response----', response)
  return { status: response?.data?.status }
})

// EDIT LESSON CONTENTS
export const updateLessonContentsVideo = createAsyncThunk('clinicNewSections/updateLessonContentsVideo', async (data, { dispatch }) => {
  const response = await axiosRequest.post(newsections?.UPDATE_LESSONS_CONTENTS, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (data) => {
      dispatch(setLessonContentsVideoUplader(Math.round(100 * (data.loaded / data.total))))
    }
  })
  return { status: response?.data?.status }
})

export const setLessonContentsVideoUplader = createAsyncThunk('clinicNewSections/setLessonContentsVideoUplader', async (data) => {
  console.log('-----onUploadProgress--data---', data)
  return { data }
})

//SET OVERVIEW DATA
export const setOverViewData = createAsyncThunk('clinicNewSections/setOverViewData', async (data) => {
  return { data }
})

//SET OVERVIEW DATA
export const setLessonContentwData = createAsyncThunk('clinicNewSections/setLessonContentwData', async (data) => {
  return { data }
})

// UPDATE ACCESS ROLES BY ADMIN
export const updateAccessRulesByAdmin = createAsyncThunk('clinicNewSections/updateAccessRulesByAdmin', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_ACCESS_RULES, data)
  return { status: response?.data?.status }
})

// UPDATE MEDITATION ACCESS ROLES BY ADMIN
export const updateMeditationAccessRulesByAdmin = createAsyncThunk('clinicNewSections/updateMeditationAccessRulesByAdmin', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_ACCESS_RULES_MEDITATION, data)
  return { status: response?.data?.status }
})

export const getAccessRulesByAdmin = createAsyncThunk('clinicNewSections/getAccessRulesByAdmin', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_ACCESS_RULES, data)
  return { data: response?.data?.clinic_ids }
})
export const setAccessRulesByAdmin = createAsyncThunk('clinicNewSections/setAccessRulesByAdmin', async (data) => {
  return { data }
})
// MEDITATION ACCESS ROLE IDS
export const getMeditationAccessRulesByAdmin = createAsyncThunk('clinicNewSections/getMeditationAccessRulesByAdmin', async () => {
  const response = await axiosRequest.post(newsections?.GET_ACCESS_RULES_MEDITATION)
  return { data: response?.data?.clinic_ids }
})

// DUBLICAT CLASSESS BY ADMIN
export const dublicateClassByAdmin = createAsyncThunk('clinicNewSections/dublicateClassByAdmin', async (data) => {
  const response = await axiosRequest.post(newsections?.DUBLICATE_CLASS, data)
  return { status: response?.data?.status }
})

export const setOverViewDataMeditation = createAsyncThunk('clinicNewSections/setOverViewDataMeditation', async (data) => {
  return { data }
})

export const setSubmitLoading = createAsyncThunk('adminSections/setSubmitLoading', async (status) => {
  return { status }
})


export const getCompleteLessonContent = createAsyncThunk('clinicNewSections/getCompleteLessonContent', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_LESSONS_COMPLETE_CONTENTS, data)
  return { data: response?.data?.data ?? [] }
})

// GET LESSON TASKS
export const getLessonsTasks = createAsyncThunk('clinicNewSections/getLessonsTasks', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_LESSONS_TASKS, data)
  return { data: response?.data?.data?.tasks ?? [] }
})

// 
// CREATE LESSON TASKS
export const createLessonsTasks = createAsyncThunk('clinicNewSections/createLessonsTasks', async (data, { dispatch }) => {
  const response = await axiosRequest.post(newsections?.CREATE_LESSONS_TASKS, data)
  if (response?.data?.status) {
    dispatch(getLessonsTasks({ data_id: data?.data_id }))
  }
  return { status: response?.data?.status ?? [] }
})

// UPDATE LESSON TASKS
export const updateLessonsTasks = createAsyncThunk('clinicNewSections/updateLessonsTasks', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_LESSONS_TASKS, data)
  return { status: response?.data?.status ?? [] }
})

export const setTasks = createAsyncThunk('clinicSections/setTasks', async (data) => {
  return { data }
})

export const updateTasks = createAsyncThunk('clinicSections/updateTasks', async (data) => {
  return { data }
})

// DELETE LESSONS
export const setDeleteLesson = createAsyncThunk('clinicSections/setDeleteLesson', async (data) => {
  return { data }
})

// DELETE LESSON 
export const deleteLesson = createAsyncThunk('clinicNewSections/deleteLesson', async (id) => {
  const response = await axiosRequest.post(newsections?.DELETE_LESSON, { id })
  return { status: response?.data ?? [] }
})
// DELETE CONTENT 
export const deleteContent = createAsyncThunk('clinicNewSections/deleteContent', async (data, { dispatch }) => {
  const response = await axiosRequest.post(newsections?.DELETE_CONTENT, { id: data?.id })
  if (response?.data?.status) {
    dispatch(getLessonsAndContents({ class_id: data?.class_id }))
  }
  return { status: response?.data ?? [] }
})
// DELETE LESSON TASKS
export const deleteTask = createAsyncThunk('clinicNewSections/deleteTask', async (id) => {
  const response = await axiosRequest.post(newsections?.DELETE_LESSONS_TASKS, { id })
  return { status: response?.data ?? [] }
})
// GET MEDITATION TASKS
export const getMeditationTasks = createAsyncThunk('clinicNewSections/getMeditationTasks', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_MEDITATION_TASKS, data)
  return { data: response?.data?.data?.tasks ?? [] }
})

// CREATE LESSON TASKS
export const createMeditationTasks = createAsyncThunk('clinicNewSections/createMeditationTasks', async (data, { dispatch }) => {
  const response = await axiosRequest.post(newsections?.CREATE_MEDITATION_TASKS, data)
  if (response?.data?.status) {
    dispatch(getMeditationTasks({ data_id: data?.data_id }))
  }
  return { status: response?.data?.status ?? [] }
})

// UPDATE MEDITATION TASKS
export const updateMeditatonTasks = createAsyncThunk('clinicNewSections/updateMeditatonTasks', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_LESSONS_TASKS, data)
  return { status: response?.data?.status ?? [] }
})

// DELETE LESSON TASKS
export const deleteMeditationTask = createAsyncThunk('clinicNewSections/deleteMeditationTask', async (id) => {
  const response = await axiosRequest.post(newsections?.DELETE_LESSONS_TASKS, { id })
  return { status: response?.data ?? [] }
})

// RESOURCES
// GET RESOURCES
export const getResources = createAsyncThunk('clinicNewSections/getResources', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_RESOURCES, data)
  return { data: response?.data?.data ?? [] }
})

// CREATE RESOURCES
export const createResources = createAsyncThunk('clinicNewSections/createResources', async (data) => {
  const response = await axiosRequest.post(newsections?.CREATE_RESOURCES, data)
  return { status: response?.data?.status ?? [] }
})
// DELETE RESOURCES
export const deleteResourse = createAsyncThunk('clinicNewSections/deleteResourse', async (id) => {
  const response = await axiosRequest.post(newsections?.DELETE_RESOURCES, { id })
  return { status: response?.data ?? [] }
})

// REORDER LESSONS
export const reOrderLessons = createAsyncThunk('clinicNewSections/reOrderLessons', async (data) => {
  const response = await axiosRequest.post(newsections?.REORDER_LESSONS, { content: data })
  return { status: response?.data?.status ?? [] }
})

// REORDER CONTENTS
export const reOrderContents = createAsyncThunk('clinicNewSections/reOrderContents', async (data) => {
  const response = await axiosRequest.post(newsections?.REORDER_CONTENTS, { content: data })
  return { status: response?.data?.status ?? [] }
})

// DELETE SET RESOURCES
export const setDeleteResourse = createAsyncThunk('clinicSections/setDeleteResourse', async (data) => {
  return { data }
})
export const setMeditationTasks = createAsyncThunk('clinicSections/setMeditationTasks', async (data) => {
  return { data }
})

export const setUpdateMeditationTasks = createAsyncThunk('clinicSections/setUpdateMeditationTasks', async (data) => {
  return { data }
})

export const setAdminSections = createAsyncThunk('clinicSections/setAdminSections', async (data) => {
  return { data }
})

export const setLessonsAndContentData = createAsyncThunk('clinicSections/setLessonsAndContentData', async (data) => {
  return { data }
})

export const setGoBackToggle = createAsyncThunk('clinicSections/setGoBackToggle', async (data) => {
  console.log('-------setGoBackToggle---data---id---', data)
  return { data }
})

export const setMeditationFavourit = createAsyncThunk('adminSections/setMeditationFavourit', async (data) => {
  return { data }
})

export const createAdminSection = createAsyncThunk('clinicSections/createAdminSection', async (data, { dispatch }) => {

  const response = await axiosRequest.post(newsections.CLINIC_ADD_SECTION, data)
  if (response?.data?.status) {
    dispatch(getAdminSections())
  }
  return { status: response?.data?.status }
})

export const updateAdminSection = createAsyncThunk('clinicSections/updateAdminSection', async (data, { dispatch }) => {

  const response = await axiosRequest.post(newsections.CLINIC_EDIT_SECTION, data)
  if (response?.data?.status) {
    dispatch(getAdminSections())
  }
  return { status: response?.data?.status }
})

export const deleteAdminSection = createAsyncThunk('clinicSections/deleteAdminSection', async (id) => {
  const response = await axiosRequest.post(newsections.CLINIC_DELETE_SECTION, { id })
  return { id, status: response.data?.status }
})

export const custommeditationreview = createAsyncThunk('clinicSections/custommeditationreview', async (data) => {
  const response = await axiosRequest.post(newsections.CUSTOM_MEDITATION_REVIEW, (data))
  return { id, status: response.data?.status }
})

export const updateLogs = createAsyncThunk('clinicSections/updateLogs', async (params) => {
  for (const pair of params.entries()) {
    console.log('----pair----', pair)
  }
  const response = await axiosRequest.post(newsections?.UPDATE_LOG, params)
  console.log('----updateLogs----response---', response)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})

export const createsubcategoryMeditations = createAsyncThunk('clinicNewSections/createsubcategoryMeditations', async (data) => {
  const response = await axiosRequest.post(newsections?.MEDITATION_SUB_CATEGORIES, data)
  console.log('------------createMeditations-------response----', response?.data?.status)
  return { status: response?.data?.status, data: response?.data  ?? [] }
})

export const getsubcategoryMeditations = createAsyncThunk('clinicNewSections/getsubcategoryMeditations', async (data) => {
  const response = await axiosRequest.post(newsections?.GET_MEDITATION_SUB_CATEGORIES, data)
  console.log('------------createMeditations-------response----', response?.data?.status)
  return { status: response?.data?.status, data: response?.data  ?? [] }
})

export const updatesubcategoryMeditations = createAsyncThunk('clinicNewSections/updatesubcategoryMeditations', async (data) => {
  const response = await axiosRequest.post(newsections?.UPDATE_MEDITATION_SUB_CATEGORIES, data)
  console.log('------------createMeditations-------response----', response?.data?.status)
  return { status: response?.data?.status, data: response?.data  ?? [] }
})

export const getMeditationsbysubcategory = createAsyncThunk('clinicNewSections/getMeditationsbysubcategory', async (data) => {
  console.log("data64654554:", data)
  const response = await axiosRequest.post(newsections?.GET_MEDITATION_BY_SUB_CATEGORIES, data)
  console.log('------------createMeditations-------response----', response?.data?.status)
  return { status: response?.data?.status, data: response?.data  ?? [] }
})

export const clinicSectionsSlice = createSlice({
  name: 'clinicNewSections',
  initialState: {
    data: [],
    categoriesList: [],
    categoryiesLoader: true,
    categoriesListByType: [],
    categoryiesLoaderByType: true,
    meditationCategryList: [],
    lessonMeditation: [],
    meditationCategoryLoader: true,
    meditationLessonLoader: true,
    meditationCategryListByType: [],
    meditationCategoryLoaderByType: true,
    classesByType: [],
    classesLoader: true,
    allSharedClasses: [],
    getFeedBackValue: [],
    addDurationAudio: [],
    allSharedClassesLoader: true,
    allSharedMeditation: [],
    allSharedMeditationLoader: true,
    allClassesLoader: true,
    allClassesData: [],
    allMeditationLoader: true,
    allMeditationData: [],
    meditationByType: [],
    meditationLoader: true,
    meditationByCategoryLoader: true,
    mediatationByCategory: [],
    classesByCategoryLoader: true,
    classesByCategory: [],
    completeClassLoader: true,
    completeClassData: {},
    completeMeditationLoader: true,
    completeMeditationData: {},
    loading: false,
    updateclassCategory: [],
    lessons: {},
    lessonsAndContents: [],
    completeLessonContent: [],
    completeContentLoader: true,
    getFeedbackLoader: false,
    popularLoader: true,
    popularClassesData: [],
    recommendedLoader: true,
    recommendedClassesData: [],
    lessonsTasks: [],
    meditaitonTasks: [],
    resources: [],
    submitLoading: false,
    videoProgress: '',
    porgressModalLoader: false,
    lessonVideoProgress: '',
    lessonPorgressModalLoader: false,
    audioProgress: '',
    porgressAudioModalLoader: false,
    authorsList: [],
    accessRulesIds: [],
    accessRulesIdsLoader: true,
    meditationAccessRulesIds: [],
    meditationAccessRulesIdsLoader: true,
    toggleValue: "1",
    lessonID: '',
    updateClassDetails: false,
    updateClassLessonDetails: false,
    updateContentDetails: false,
    getMoreMeditationAudios: [],
    updateMeditationDetails: false,
    mediatationBySubCategory:[]
  },

  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setSubmitLoading.fulfilled, (state, { payload }) => {
        state.submitLoading = payload?.status ?? false
      })
      .addCase(setOverViewData.fulfilled, (state, { payload }) => {
        state.completeLessonContent = payload?.data ?? []
      })
      .addCase(setLessonContentwData.fulfilled, (state, { payload }) => {
        state.completeLessonContent = payload?.data ?? []
      })
      .addCase(setOverViewDataMeditation.fulfilled, (state, { payload }) => {
        state.completeMeditationData = payload?.data ?? []
      })
      .addCase(setClassVideoUplader.fulfilled, (state, { payload }) => {
        state.videoProgress = payload?.data ?? []

        state.porgressModalLoader = true
        if (payload?.data === 100) {
          state.porgressModalLoader = false
        }
      })
      .addCase(setLessonContentsVideoUplader.fulfilled, (state, { payload }) => {
        state.lessonVideoProgress = payload?.data ?? []

        state.lessonPorgressModalLoader = true
        if (payload?.data === 100) {
          state.lessonPorgressModalLoader = false
        }
      })
      .addCase(setMeditationAudioUplader.fulfilled, (state, { payload }) => {
        state.audioProgressProgress = payload?.data ?? []

        state.porgressAudioModalLoader = true
        if (payload?.data === 100) {
          state.porgressAudioModalLoader = false
        }
      })
      .addCase(addDuration.fulfilled, () => {

      })
      .addCase(getMeditationMoreAudios.fulfilled, (state, { payload }) => {
        state.getMoreMeditationAudios = payload?.data
      })
      .addCase(setMeditationMoreAudios.fulfilled, (state, { payload }) => {
        state.getMoreMeditationAudios = payload?.data
      })
      .addCase(createMeditationMoreAudios.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('New Meditation Audio has been Added Successfully')
        }
      })

      .addCase(updateMeditationMoreAudios.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Meditation has been Added Successfully')
        }
      })
      .addCase(deleteMeditationMoreAudios.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Meditation has been Deleted Successfully')
        }
      })
      .addCase(createClassCategory.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Category has been add Successfully')
        }
        // state.loading = true
      })
      .addCase(changeClassCategory.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Category has been Updated Successfully')
        }
        // state.loading = true
      })
      .addCase(changeMeditationCategory.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Category has been Updated Successfully')
        }
        // state.loading = true
      })
      .addCase(getClassCategories.fulfilled, (state, { payload }) => {
        state.categoriesList = payload?.data
        state.categoryiesLoader = false
      })
      .addCase(getClassCategories.pending, (state) => {
        state.categoryiesLoader = true
      })
      .addCase(getClassCategories.rejected, (state) => {
        state.categoryiesLoader = false
      })
      // GET CLASS GATEGORIES BY TYPE 
      .addCase(getClassCategoriesByType.fulfilled, (state, { payload }) => {
        state.categoriesListByType = payload?.data
        state.categoryiesLoaderByType = false
      })
      .addCase(getClassCategoriesByType.pending, (state) => {
        state.categoryiesLoaderByType = true
      })
      .addCase(getClassCategoriesByType.rejected, (state) => {
        state.categoryiesLoaderByType = false
      })

      // GET MEDITATION GATEGORIES BY TYPE 
      .addCase(getMeditationCategoriesByType.fulfilled, (state, { payload }) => {
        state.meditationCategryListByType = payload?.data
        state.meditationCategoryLoaderByType = false
      })
      .addCase(getMeditationCategoriesByType.pending, (state) => {
        state.meditationCategoryLoaderByType = true
      })
      .addCase(getMeditationCategoriesByType.rejected, (state) => {
        state.meditationCategoryLoaderByType = false
      })
      //

      .addCase(getMeditationCategries.fulfilled, (state, { payload }) => {
        state.meditationCategryList = payload?.data
        state.meditationCategoryLoader = false
      })
      .addCase(getMeditationCategries.pending, (state) => {
        state.meditationCategoryLoader = true
      })
      .addCase(getMeditationCategries.rejected, (state) => {
        state.meditationCategoryLoader = false
      })
      //
      .addCase(getLessonMeditation.fulfilled, (state, { payload }) => {
        state.lessonMeditation = payload?.data
        state.meditationLessonLoader = false
      })
      .addCase(getLessonMeditation.pending, (state) => {
        state.meditationLessonLoader = true
      })
      .addCase(getLessonMeditation.rejected, (state) => {
        state.meditationLessonLoader = false
      })

      //
      .addCase(getClassByType.fulfilled, (state, { payload }) => {
        state.classesLoader = false
        state.classesByType = payload?.data
      })
      .addCase(getClassByType.pending, (state) => {
        state.classesLoader = true
      })
      .addCase(getClassByType.rejected, (state) => {
        state.classesLoader = false
      })
      .addCase(getAllSharedClasses.fulfilled, (state, { payload }) => {
        state.allSharedClassesLoader = false
        state.allSharedClasses = payload?.data
      })
      .addCase(getAllSharedMeditation.fulfilled, (state, { payload }) => {
        state.allSharedMeditationLoader = false
        state.allSharedMeditation = payload?.data
      })
      .addCase(getAllSharedMeditation.rejected, (state) => {
        state.allSharedMeditationLoader = false
      })
      .addCase(getAllSharedMeditation.pending, (state) => {
        state.allSharedMeditationLoader = true
      })
      .addCase(setAllSharedMeditation.fulfilled, (state, { payload }) => {
        state.allSharedMeditation = payload?.data
      })
      // GET ALL CLASSES
      .addCase(getAllSharedClasses.pending, (state) => {
        state.allSharedClassesLoader = true
      })
      .addCase(getAllSharedClasses.rejected, (state) => {
        state.allSharedClassesLoader = false
      })
      .addCase(setAllSharedClasses.fulfilled, (state, { payload }) => {
        state.allSharedClasses = payload?.data
      })
      .addCase(updateAllSharedClasses.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Updated Successfully')
        }
      })
      // GET ALL CLASSES
      .addCase(getAllClasses.fulfilled, (state, { payload }) => {
        state.allClassesLoader = false
        state.allClassesData = payload?.data
      })
      .addCase(getAllClasses.pending, (state) => {
        state.allClassesLoader = true
      })
      .addCase(getAllClasses.rejected, (state) => {
        state.allClassesLoader = false
      })

      // GET ALL MEDITATION
      .addCase(getAllMeditation.fulfilled, (state, { payload }) => {
        state.allMeditationLoader = false
        state.allMeditationData = payload?.data
      })
      .addCase(getAllMeditation.pending, (state) => {
        state.allMeditationLoader = true
      })
      .addCase(getAllMeditation.rejected, (state) => {
        state.allMeditationLoader = false
      })
      .addCase(deleteClasses.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Deleted Successfully')
        }
      })
      .addCase(addToFeature.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Featured Successfully')
        }
      })
      //
      .addCase(deleteCatagories.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Deleted Successfully')
        }
      })
      //
      .addCase(deleteMeditaion.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Deleted Successfully')
        }
      })
      //
      .addCase(getCompleteClass.fulfilled, (state, { payload }) => {
        state.completeClassLoader = false
        state.completeClassData = payload?.data
      })
      .addCase(getCompleteClass.pending, (state) => {
        state.completeClassLoader = true
      })
      .addCase(getCompleteClass.rejected, (state) => {
        state.completeClassLoader = false
      })
      .addCase(setCompleteClassData.fulfilled, (state, { payload }) => {
        state.completeClassData = payload?.data
      })
      .addCase(getCompleteMeditation.fulfilled, (state, { payload }) => {
        state.completeMeditationLoader = false
        state.completeMeditationData = payload?.data
      })
      .addCase(getCompleteMeditation.pending, (state) => {
        state.completeMeditationLoader = true
      })
      .addCase(getCompleteMeditation.rejected, (state) => {
        state.completeMeditationLoader = false
      })
      .addCase(getMeditationByType.fulfilled, (state, { payload }) => {
        console.log('---FULFILLED---', payload?.data)
        state.meditationLoader = false
        state.meditationByType = payload?.data
      })
      .addCase(getMeditationByType.pending, (state) => {
        state.meditationLoader = true
      })
      .addCase(getMeditationByType.rejected, (state) => {
        state.meditationLoader = false
      })
      .addCase(getMeditationByCategory.fulfilled, (state, { payload }) => {
        console.log('---FULFILLED---', payload?.data)
        state.meditationByCategoryLoader = false
        state.mediatationByCategory = payload?.data
      })
      .addCase(getMeditationByCategory.pending, (state) => {
        state.meditationByCategoryLoader = true
      })
      .addCase(getMeditationByCategory.rejected, (state) => {
        state.meditationByCategoryLoader = false
      })

      .addCase(getClassesByCategory.fulfilled, (state, { payload }) => {
        console.log('---FULFILLED---', payload?.data)
        state.classesByCategoryLoader = false
        state.classesByCategory = payload?.data
      })
      .addCase(getClassesByCategory.pending, (state) => {
        state.classesByCategoryLoader = true
      })
      .addCase(getClassesByCategory.rejected, (state) => {
        state.classesByCategoryLoader = false
      })
      .addCase(getRecommendByType.fulfilled, (state, { payload }) => {
        console.log('---FULFILLED---', payload?.data)
        state.recommendedLoader = false
        state.recommendedClassesData = payload?.data
      })
      .addCase(getRecommendByType.pending, (state) => {
        state.recommendedLoader = true
      })
      .addCase(getRecommendByType.rejected, (state) => {
        state.recommendedLoader = false
      })
      .addCase(getPopularByType.fulfilled, (state, { payload }) => {
        console.log('---FULFILLED---', payload?.data)
        state.popularLoader = false
        state.popularClassesData = payload?.data
      })
      .addCase(getPopularByType.pending, (state) => {
        state.popularLoader = true
      })
      .addCase(getPopularByType.rejected, (state) => {
        state.popularLoader = false
      })
      .addCase(getAuthorsList.fulfilled, (state, { payload }) => {
        console.log('-getAuthorsList--FULFILLED---', payload?.data)
        state.authorsList = payload?.data
      })
      .addCase(createClasses.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Added Successfully')
        }
      })
      .addCase(updateClass.fulfilled, (state, { payload }) => {

        state.updateClassDetails = payload?.status
        // if (payload?.status) {
        //   displaySuccessToast('Class has been Updated Successfully')
        // }
      })
      .addCase(updateClass.pending, (state) => {
        state.updateClassDetails = false
      })
      .addCase(updateClass.rejected, (state) => {
        state.updateClassDetails = true
      })
      .addCase(updateClassVideo.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Updated Successfully')
        }
      })
      // ADD MEDITATION AUDIO
      .addCase(updateMeditationAudio.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Meditation has been Updated Successfully')
        }
      })
      .addCase(addFavouritClass.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Updated Successfully')
        }
      })
      .addCase(removeFavouritClass.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Updated Successfully')
        }
      })
      .addCase(updateLesson.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Lesson has been Updated Successfully')
        }
      })
      .addCase(updateMeditation.fulfilled, (state, { payload }) => {
        state.updateMeditationDetails = payload?.status
        if (payload?.status) {
          displaySuccessToast('Meditation has been Updated Successfully')
        }
      })
      .addCase(updateMeditation.pending, (state) => {
        state.updateMeditationDetails = false
      })
      .addCase(updateMeditation.rejected, (state) => {
        state.updateMeditationDetails = true
      })
      //
      .addCase(updateClassCategory.fulfilled, (state, { payload }) => {
        state.updateclassCategory = payload?.status
        if (payload?.status) {
          displaySuccessToast('Class Category has been Updated Successfully')
        }
      })
      

      //
      .addCase(updateDurationMeditation.fulfilled, ({ payload }) => {
        console.log("--------updateDurationMeditation--", payload)
      })
      // RECOMMENDED
      // CREATE RECOMMENDED
      .addCase(createRecommendedData.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Recommended has been Updated Successfully')
        }
      })

      // CREATE RECOMMENDED MEDITATION
      .addCase(createRecommendedMeditation.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Recommended has been Updated Successfully')
        }
      })
      // POPULAR
      // CREATE POPULAR
      .addCase(createPopularData.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Popular has been Updated Successfully')
        }
      })
      // CREATE POPULAR MEDITATION
      .addCase(createPopularMeditation.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Popular has been Updated Successfully')
        }
      })
      .addCase(createMeditations.fulfilled, (state, { payload }) => {
       
        console.log("fulfilled...", payload?.data)
        if (payload?.status) {
          displaySuccessToast('Meditation has been Added Successfully')
        }
      })
      .addCase(createLessons.fulfilled, (state, { payload }) => {
        state.updateClassLessonDetails = false
        state.lessons = payload?.data
        // if (payload?.status) {
        //   state.lessons = payload?.data
        //   displaySuccessToast('Lesson has been Added Successfully')
        // }

      })
      .addCase(createLessons.pending, (state) => {
        state.updateClassLessonDetails = true
      })
      .addCase(createLessons.rejected, (state) => {
        state.updateClassLessonDetails = false
      })
      .addCase(createLessonContents.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Content has been Added Successfully')
        }
      })
      .addCase(updateLessonContents.fulfilled, (state, { payload }) => {
        state.updateContentDetails = payload?.status
        // if (payload?.status) {
        //   displaySuccessToast('Content has been Updated Successfully')
        // }
      })
      .addCase(updateLessonContents.pending, (state) => {
        state.updateContentDetails = false

      })
      .addCase(updateLessonContents.rejected, (state) => {
        state.updateContentDetails = true

      })
      .addCase(updateLessonContentsVideo.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Content has been Updated Successfully')
        }
      })

      .addCase(getLessonsAndContents.fulfilled, (state, { payload }) => {
        state.lessonsAndContents = payload?.data
      })
      .addCase(setLessonsAndContentData.fulfilled, (state, { payload }) => {
        state.lessonsAndContents = payload?.data
      })
      .addCase(setGoBackToggle.fulfilled, (state, { payload }) => {
        state.toggleValue = payload?.data?.data
        state.lessonID = payload?.data?.id

      })
      .addCase(setMeditationFavourit.fulfilled, (state, { payload }) => {
        state.completeMeditationData = payload?.data
      })
      .addCase(getCompleteLessonContent.fulfilled, (state, { payload }) => {
        state.completeLessonContent = payload?.data
        state.completeContentLoader = false
      })
      .addCase(getCompleteLessonContent.pending, (state) => {
        state.completeContentLoader = true
      })
      .addCase(getCompleteLessonContent.rejected, (state) => {
        state.completeContentLoader = false
      })
      .addCase(getFeedBackData.fulfilled, (state, { payload }) => {
        state.getFeedBackValue = payload?.data
        state.getFeedbackLoader = false
      })
      .addCase(getFeedBackData.pending, (state) => {
        state.getFeedbackLoader = true
      })
      .addCase(getFeedBackData.rejected, (state) => {
        state.getFeedbackLoader = false
      })
      .addCase(getLessonsTasks.fulfilled, (state, { payload }) => {
        state.lessonsTasks = payload?.data
      })
      .addCase(createLessonsTasks.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Task has been Addes Successfully')
        }
      })
      .addCase(updateLessonsTasks.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Task has been Updated Successfully')
        }
      })
      .addCase(setTasks.fulfilled, (state, { payload }) => {
        state.lessonsTasks = payload?.data
      })
      .addCase(deleteTask.fulfilled, (state, { payload }) => {
        console.log('---deleted successfully-----', payload?.status)
      })
      .addCase(updateTasks.fulfilled, (state, { payload }) => {
        state.lessonsTasks = payload?.data
      })
      // MEDITATION SECTION
      .addCase(getMeditationTasks.fulfilled, (state, { payload }) => {
        state.meditaitonTasks = payload?.data
      })
      .addCase(createMeditationTasks.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Task has been Addes Successfully')
        }
      })
      .addCase(updateMeditatonTasks.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Task has been Update Successfully')
        }
      })
      .addCase(addFavouritMeditation.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Updated Successfully')
        }
      })
      .addCase(removeFavouritMeditation.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Class has been Updated Successfully')
        }
      })
      .addCase(sendnotifications.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Notification has been Send Successfully')
        }
      })
      .addCase(setMeditationTasks.fulfilled, (state, { payload }) => {
        state.meditaitonTasks = payload?.data
      })
      .addCase(setUpdateMeditationTasks.fulfilled, (state, { payload }) => {
        state.meditaitonTasks = payload?.data
      })
      .addCase(deleteMeditationTask.fulfilled, (state, { payload }) => {
        console.log('---deleted successfully-----', payload?.status)
      })
      .addCase(setDeleteLesson.fulfilled, (state, { payload }) => {
        state.lessonsAndContents = payload?.data
      })
      .addCase(deleteLesson.fulfilled, (state, { payload }) => {
        console.log('---deleted successfully-----', payload?.status)
      })
      .addCase(deleteContent.fulfilled, (state, { payload }) => {
        console.log('---deleted successfully-----', payload?.status)
        if (payload?.status) {
          displaySuccessToast('Content has been deleted Successfully')
        }
      })

      // RESOURCES
      .addCase(getResources.fulfilled, (state, { payload }) => {
        state.resources = payload?.data
      })
      .addCase(createResources.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Resource has been Addes Successfully')
        }
      })
      .addCase(setDeleteResourse.fulfilled, (state, { payload }) => {
        state.resources = payload?.data
      })
      .addCase(reOrderLessons.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Lessons has been Re-Orderd Successfully')
        }
      })
      .addCase(reOrderContents.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Contents has been Re-Orderd Successfully')
        }
      })
      // .addCase(getAdminSections.fulfilled, (state, { payload }) => {
      //   console.log("FULFILLED...")
      //   state.data = payload?.data ?? []
      //   state.loading = false
      //   state.params = payload?.params
      //   state.totalSections = payload?.data?.length
      // })
      // .addCase(getAdminSections.pending, (state) => {
      //   console.log("LOADING...")
      //   state.loading = true
      // })
      // .addCase(getAdminSections.rejected, (state) => {
      //   console.log("REJECTED...")
      //   state.loading = false
      // })
      .addCase(setAdminSections.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(createAdminSection.fulfilled, (state, { payload }) => {
        displaySuccessToast('Clinic Section has been Added Successfully')
        console.log(`CREATED STATUS ${payload?.status}`)
        state.status = payload?.status ?? []

      })
      .addCase(updateAdminSection.fulfilled, (state, { payload }) => {
        displaySuccessToast('Clinic Section has been Updated Successfully')
        console.log(`UPDATED DATA ${payload?.status}`)

      })
      .addCase(deleteAdminSection.fulfilled, (state, { payload }) => {
        displaySuccessToast('Clinic Section has been Deleted Successfully')
        console.log(`DELETED DATA ID ${payload?.id}`)
      })
      .addCase(updateAccessRulesByAdmin.fulfilled, (state, { payload }) => {
        console.log('-------updateAccessRulesByAdmin---payload---', payload)
      })
      .addCase(updateMeditationAccessRulesByAdmin.fulfilled, (state, { payload }) => {
        console.log('-------updateMeditationAccessRulesByAdmin---payload---', payload)
      })
      .addCase(getAccessRulesByAdmin.fulfilled, (state, { payload }) => {
        console.log('-------getAccessRulesByAdmin---payload---', payload)
        state.accessRulesIds = payload?.data
        state.accessRulesIdsLoader = false
      })
      .addCase(getAccessRulesByAdmin.pending, (state) => {
        state.accessRulesIdsLoader = true
      })
      .addCase(getAccessRulesByAdmin.rejected, (state) => {
        state.accessRulesIdsLoader = false
      })

      .addCase(setAccessRulesByAdmin.fulfilled, (state, { payload }) => {
        state.accessRulesIds = payload?.data
      })
      // MEDITATION ACCESS ROLE IDS
      .addCase(getMeditationAccessRulesByAdmin.fulfilled, (state, { payload }) => {
        console.log('-------getMeditationAccessRulesByAdmin---payload---', payload)
        state.accessRulesIds = payload?.data
        state.accessRulesIdsLoader = false
      })
      .addCase(getMeditationAccessRulesByAdmin.pending, (state) => {
        state.accessRulesIdsLoader = true
      })
      .addCase(getMeditationAccessRulesByAdmin.rejected, (state) => {
        state.accessRulesIdsLoader = false
      })
      .addCase(dublicateClassByAdmin.fulfilled, (state, { payload }) => {
        console.log('-------dublicateClassByAdmin---payload---', payload)
      })
      .addCase(custommeditationreview.fulfilled, (state, action) => {
        // Handle success case
        console.log('Review sent successfully:', action.payload)
      })
      .addCase(custommeditationreview.rejected, (state, action) => {
        // Handle error case
        console.error('Failed to send review:', action.error.message)
      })
      .addCase(updateLogs.fulfilled, ({ payload }) => {
        console.log(payload?.status)
      })
      .addCase(createsubcategoryMeditations.pending, (state) => {
        state.status = 'loading'
        state.error = null // Clear previous errors
      })
      .addCase(createsubcategoryMeditations.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload.data // Store the data
      })
      .addCase(createsubcategoryMeditations.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload?.message || 'Failed to create meditation subcategory'
      })
      .addCase(getsubcategoryMeditations.pending, (state) => {
        state.status = 'loading'
        state.error = null // Clear previous errors
      })
      .addCase(getsubcategoryMeditations.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload.data // Store the data
      })
      .addCase(getsubcategoryMeditations.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload?.message || 'Failed to create meditation subcategory'
      })
      .addCase(deleteSubCatagories.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(deleteSubCatagories.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.success = action.payload.status
      })
      .addCase(deleteSubCatagories.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Something went wrong'
        state.success = false
      })
      .addCase(updatesubcategoryMeditations.pending, (state) => {
        state.loading = true
        state.error = null // Reset any previous errors
      })
      .addCase(updatesubcategoryMeditations.fulfilled, (state, action) => {
        state.loading = false
        state.status = action.payload.status
        state.data = action.payload.data
      })
      .addCase(updatesubcategoryMeditations.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'An error occurred'
      })
      .addCase(getMeditationsbysubcategory.pending, (state) => {
        state.loading = 'loading'
        state.error = null
      })
      .addCase(getMeditationsbysubcategory.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        state.mediatationBySubCategory  = action.payload.data
      })
      .addCase(getMeditationsbysubcategory.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.payload || 'Something went wrong'
      })
  }
})

export default clinicSectionsSlice.reducer
